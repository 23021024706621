import { createSlice } from '@reduxjs/toolkit';
import { loadSettingsDataSuccess } from '../../../shared/app-slice';
import { SMS_WIDGET } from '../../products/settings/settings-constants';

const initialState = {
  smsInfo: {
    areaCode: null,
    countryCode: 'US',
    size: 1,
    type: 'LOCAL',
  },
  error: false,
  saveSuccess: false,
  generating: false,
  generatedNumber: '',
  saveGeneratedNumber: false,
};

const smsSlice = createSlice({
  name: 'sms',
  initialState,
  reducers: {
    setAreaCode: (state, action) => {
      state.smsInfo.areaCode = Number(action.payload.value);
    },
    generateProvision: (state) => {
      state.generating = true;
    },
    generateProvisionSuccess: (state, action) => {
      state.generating = false;
      state.generatedNumber = action.payload[0];

      if (!action.payload[0]) {
        state.error = true;
      } else {
        state.error = false;
      }
    },
    generateProvisionFailed: (state) => {
      state.generating = false;
      state.error = true;
    },
    saveProvisionNumber: (state, action) => { },
    saveProvisionNumberSuccess: (state) => {
      state.saveGeneratedNumber = true;
    },
    saveProvisionNumberFailed: (state) => {
      state.saveGeneratedNumber = false;
    },
    resetError: (state) => {
      state.error = false;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(loadSettingsDataSuccess, (state, action) => {
      const listOfProducts = action.payload.products;

      listOfProducts?.forEach((el) => {
        if (el.offeringType === SMS_WIDGET) {
          state.generatedNumber = el.productInfo.phoneNumber;
        }
      });

      if (action.payload.business?.gmaid) {
        const gmaid = action.payload.business?.gmaid;
        const isCanadianGmaid = gmaid.includes('CAN');
        state.smsInfo.countryCode = isCanadianGmaid ? 'CA' : 'US';
        state.smsInfo.type = 'LOCAL';
      }
    });
  },
});

export const {
  setAreaCode,
  generateProvision,
  generateProvisionSuccess,
  generateProvisionFailed,
  saveProvisionNumber,
  saveProvisionNumberSuccess,
  saveProvisionNumberFailed,
  resetError,
} = smsSlice.actions;

export default smsSlice.reducer;
