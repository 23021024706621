import { useMemo, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { FormProvider, useFieldArray, useForm } from 'react-hook-form';
import * as yup from 'yup';
import { ValidationProvider } from '@gannettdigital/shared-react-components';
import { yupResolver } from '@hookform/resolvers/yup';
import { Grid } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import EmailInputArrayField from '../EmailInputArrayField';
import { APPT_REMINDER_SMS, APPT_RESCHEDULE_CANCEL_SMS, NEW_APPT_SMS, NOTIFICATIONS_FORM, NOTIFICATION_EMAILS_KEY, SMS_MESSAGES_DASH, SMS_NOTIFICATION_DASH, SMS_PHONE_NUMBER_KEY } from "../../settings-constants";
import { NOTIFICATIONS_SETTINGS_SECTION, SMS_NOTIFICATIONS_SECTION, addEmailNotifications, addSmsPhoneNumber, removeEmailNotifications, removeSmsPhoneNumber, updateEmailNotifications, updateSmsPhoneNumber } from '../../data/notifications-slice';
import SectionCard from '../../../../../shared/SectionCard';
import BookingsCTA from '../../../../../shared/BookingsCTA';
import { GridRow } from '../../../../../shared/settings-shared-components';
import { PHONE_NUMBER_REGEX, isValidUSPhoneNumberLength } from '../../../../../shared/validation-utils';
import WidgetSettingsStateChangedHook from '../../../../../shared/stateChangedHook/WidgetSettingsStateChangedHook';
import PhoneInputArrayField from '../PhoneInputArrayField';

const FORM_KEYS = [
  NOTIFICATION_EMAILS_KEY,
  SMS_PHONE_NUMBER_KEY,
];

export default function NotificationsSettingsCard() {
  const dispatch = useDispatch();
  const allEmails = useSelector((state) => state.settings.notifications[NOTIFICATIONS_SETTINGS_SECTION][NOTIFICATION_EMAILS_KEY]);
  const smsPhones = useSelector((state) => state.settings.notifications[NOTIFICATIONS_SETTINGS_SECTION][SMS_PHONE_NUMBER_KEY]);
  const newApptNotification = useSelector((state) => state.settings.notifications[SMS_NOTIFICATIONS_SECTION][NEW_APPT_SMS]);
  const apptReminderNotification = useSelector((state) => state.settings.notifications[SMS_NOTIFICATIONS_SECTION][APPT_REMINDER_SMS]);
  const rescheduleNotification = useSelector((state) => state.settings.notifications[SMS_NOTIFICATIONS_SECTION][APPT_RESCHEDULE_CANCEL_SMS]);
  const smsDashNotification = useSelector((state) => state.settings.notifications[SMS_NOTIFICATIONS_SECTION][SMS_NOTIFICATION_DASH]);
  const smsNewMessage = useSelector((state) => state.settings.notifications[SMS_NOTIFICATIONS_SECTION][SMS_MESSAGES_DASH]);
  const isSMSChecked = newApptNotification || apptReminderNotification || rescheduleNotification || smsDashNotification || smsNewMessage;
  
  const emailSchema = yup.object().shape({
    email: yup.string().email('Invalid email address').required('Email address is required!')
  });

  const phoneSchema = yup.object().shape({
    phone: yup.lazy((value) => (value || isSMSChecked
      ? yup.string().test('len', 'Phone Number must be 10 digits long', (val) => {
        if (!val) {
          return false;
        }
        return isValidUSPhoneNumberLength(val);
      }).matches(PHONE_NUMBER_REGEX, 'Phone Number is invalid').nullable()
      : yup.mixed().notRequired()))
  });
  const notificationSettingsSchema = yup.object().shape({
    [NOTIFICATION_EMAILS_KEY]: yup.array().of(emailSchema),
    [SMS_PHONE_NUMBER_KEY]: yup.array().of(phoneSchema),
  });

  const methods = useForm({
    mode: 'all',
    resolver: yupResolver(notificationSettingsSchema),
    defaultValues: useMemo(() => ({
      [NOTIFICATION_EMAILS_KEY]: allEmails,
      [SMS_PHONE_NUMBER_KEY]: smsPhones
    }))
  });

  const { formState: { errors }, control, trigger, clearErrors } = methods;

  const { fields: emailFields, append: appendEmail, remove: removeEmail } = useFieldArray({
    control,
    name: NOTIFICATION_EMAILS_KEY
  });

  const { fields: phoneFields, append: appendPhone, remove: removePhone } = useFieldArray({
    control,
    name: SMS_PHONE_NUMBER_KEY
  })

  const disableAddEmailBtn = !!errors?.notificationEmails || emailFields.length === 3;
  const disableAddPhoneBtn = !!errors?.[SMS_PHONE_NUMBER_KEY] || phoneFields.length === 3 || !isSMSChecked;

  const handleOnRemoveEmail = (index) => {
    removeEmail(index);

    dispatch(
      removeEmailNotifications({ index })
    );
  };

  const handleOnAddEmail = () => {
    appendEmail({ email: '' });

    dispatch(
      addEmailNotifications()
    );
  };

  const handleOnEmailInputChange = (event, index) => {
    const value = event.target.value;

    dispatch(
      updateEmailNotifications({ value, index })
    );
    return event;
  };

  const handleOnAddPhone = () => {
    appendPhone({ phone: '' });

    dispatch(
      addSmsPhoneNumber()
    );
  }

  const handleOnRemovePhone = (index) => {
    removePhone(index);

    dispatch(
      removeSmsPhoneNumber({ index })
    );
  }

  const handlePhoneNumberInputChange = (value, index) => {
    dispatch(
      updateSmsPhoneNumber({ value, index })
    );
  };

  useEffect(() => {
    // If any SMS options are true
    if (newApptNotification || apptReminderNotification || rescheduleNotification || smsDashNotification || smsNewMessage) {
      // Check if the number is valid
      trigger(SMS_PHONE_NUMBER_KEY, { shouldFocus: true });
    } else if (!newApptNotification && !apptReminderNotification && !rescheduleNotification && !smsDashNotification && !smsNewMessage) {
      // Clean up the error if no longer required
      clearErrors(SMS_PHONE_NUMBER_KEY);
      trigger(SMS_PHONE_NUMBER_KEY);
    }
  }, [newApptNotification, apptReminderNotification, rescheduleNotification, smsDashNotification, smsNewMessage]);

  return (
    <SectionCard
      id="notifications-settings"
      title="Notification Settings"
      tooltipText="Specify where email and SMS notifications should be delivered. You can add up to 3 email addresses."
      showDividingLine={false}>
        <ValidationProvider schema={notificationSettingsSchema}>
          <FormProvider {...methods}>
            <form style={{ marginTop: '24px' }}>
              <Grid container spacing={2}>
                {
                  emailFields.map((item, index) => (
                    <EmailInputArrayField
                      key={item.id}
                      item={item}
                      index={index}
                      handleOnEmailInputChange={handleOnEmailInputChange}
                      handleOnRemoveEmail={handleOnRemoveEmail}
                      errors={errors}
                    />
                  ))
                }
                <GridRow item md={12} sm={12} xs={12}>
                  <BookingsCTA
                    text='Add Email Address'
                    startIcon={<AddIcon />}
                    onClickHandler={handleOnAddEmail}
                    disabled={disableAddEmailBtn}
                   />
                </GridRow>
                {
                  phoneFields.map((item, index) => (
                    <PhoneInputArrayField
                      key={item.id}
                      item={item}
                      index={index}
                      handleOnPhoneInputChange={handlePhoneNumberInputChange}
                      handleOnRemovePhone={handleOnRemovePhone}
                      errors={errors}
                      isSMSChecked={isSMSChecked}
                    />
                  ))
                }
                <GridRow item md={12} sm={12} xs={12}>
                  <BookingsCTA
                    text='Add Phone Number'
                    startIcon={<AddIcon />}
                    onClickHandler={handleOnAddPhone}
                    disabled={disableAddPhoneBtn}
                   />
                </GridRow>
              </Grid>
              <WidgetSettingsStateChangedHook formName={NOTIFICATIONS_FORM} formKeys={FORM_KEYS} subsectionKey={NOTIFICATIONS_SETTINGS_SECTION} />
            </form>
          </FormProvider>
        </ValidationProvider>
    </SectionCard>
  )
}